import React from "react";

import { indexAct } from "../api/indexAct";

import { Link } from "react-router-dom";

import { Carousel, Card, message, Spin } from "antd";

import "antd/es/carousel/style/css";
import "antd/es/card/style/css";

export default class NewsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  componentDidMount() {
    this.getNews();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.lang !== prevProps.lang) {
      this.getNews();
    }
  }

  getNews = () => {
    indexAct("GET_NEWS_HOME", {}, "/").then((data) => {
      if (data.code === 200) {
        this.setState((state) => {
          state.loading = false;
          state.data = data.res;
          return state;
        });
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  render() {
    const { loading, data } = this.state;
    return (
      <>
        <div className="ant-segment" id="news">
          <div className="ribbon">
            <span className="page-title-ribbon-content">
              {" "}
              <a href="/news" rel="noopener noreferrer">
                {
                  this.props.info.ROUTES.find((el) => el.COMPONENT === "News")
                    .PAGE_NAME
                }
              </a>
            </span>
          </div>
          <div className="page-content">
            {loading ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <Carousel
                slidesToShow={data.length < 4 ? data.length : 4}
                autoplay={false}
                pauseOnHover={false}
                dots={false}
                draggable={false}
                responsive={[
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      autoplay: true,
                      draggable: true,
                    },
                  },
                ]}
              >
                {data.map((el, i) => {
                  return (
                    <Link to={`news/${el.URL}`} key={i} className="news-card">
                      <Card
                        size="small"
                        cover={
                          <img
                            style={{
                              width: "100%",
                              maxWidth: "400px",
                              height: "auto",
                            }}
                            alt="example"
                            src={
                              el.IMAGE.length > 0
                                ? window.APP.MEDIA + el.IMAGE
                                : `${window.APP.MEDIA}no-image.png`
                            }
                          />
                        }
                      >
                        <Card.Meta title={el.NAME} />
                      </Card>
                    </Link>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </>
    );
  }
}
