import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

window.APP = {
  //API: "http://192.168.1.5/krdf/api/",
  //baseName: "/",
  //MEDIA: "http://192.168.1.5/krdf/media/",
  API: "https://krdf.ge/api/",
  baseName: "/",
  MEDIA: "https://krdf.ge/media/",
};

ReactDOM.render(
  //<React.StrictMode>
  <BrowserRouter basename={window.APP.baseName}>
    <App />
  </BrowserRouter>,
  //</React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
