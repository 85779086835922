import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo from "./img/krdf.png";

import fbLogo from "./img/fb_logo.png";
import twLogo from "./img/tw_logo.png";

import LANG_GE from "./font/GE.svg";
import LANG_EN from "./font/EN.svg";

import { Link } from "react-router-dom";

import { Row, Col, Layout, message, Switch } from "antd";

import "antd/es/layout/style/css";

import "antd/es/row/style/css";
import "antd/es/col/style/css";
import "antd/es/message/style/css";
import "antd/es/switch/style/css";

import Loader from "./components/loader";

import device from "./functions/device";

import ContentIn from "./components/Content";
import Pages from "./components/Pages";
import PagesMobile from "./components/PagesMobile";
import { indexAct } from "./components/api/indexAct";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      device: device(),
      info: {},
      lang: "",
    };
  }

  componentDidMount() {
    this.getAppInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  getAppInfo = () => {
    indexAct("GET_APP_INFO", {}, "/").then((data) => {
      if (data.code === 200) {
        this.setState((prevState) => ({
          loading: false,
          info: { ...data.res.info },
          lang: data.res.lang,
        }));
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  changeLang = (lang) => {
    indexAct("CHANGE_LANG", { lang: lang }, "/").then((data) => {
      if (data.code === 201) {
        this.setState(
          (prevState) => ({
            lang: data.res.lang,
          }),
          () => this.getAppInfo()
        );
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <Layout>
        <Layout.Header
          className="top-header" /*  top-header-new top-header-new1 */
          style={{ position: "fixed", zIndex: 999, width: "100%" }}
        >
          <Row justify="center">
            <Col md={0} xs={6} sm={6}>
              {this.state.device.isMobile ? (
                <PagesMobile {...this.state} {...this.props} />
              ) : null}
            </Col>
            <Col md={5} xs={10} sm={10}>
              <Link to="/" className="logo" onClick={this.getAppInfo}>
                <img alt="logo" src={logo} />
              </Link>
            </Col>
            <Col md={15} xs={0} sm={0}>
              <Pages {...this.state} {...this.props} />
            </Col>
            <Col
              md={{ span: 4, push: 2 }}
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              style={{ textAlign: this.state.device.isMobile ? "center" : "" }}
            >
              <a
                href={this.state.info.CONTACTS.FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="fb_logo"
                  className="fb_logo"
                  src={fbLogo}
                  //style={{ width: "26px" }}
                />
              </a>
              <a
                href={this.state.info.CONTACTS.TWITTER}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="tw_logo"
                  src={twLogo}
                  className="tw_logo"
                  //style={{ width: "35px", marginTop: "1px" }}
                />
              </a>
              <Switch
                size={this.state.device.isMobile ? "small" : "default"}
                className="lang"
                checkedChildren={<img alt="lang_ge" src={LANG_GE} />}
                unCheckedChildren={<img alt="lang_en" src={LANG_EN} />}
                checked={this.state.lang === "GE" ? false : true}
                onChange={(checked) => {
                  const lang = checked ? "EN" : "GE";
                  this.changeLang(lang);
                }}
              />
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content style={{ marginTop: 110, minHeight: "100vh" }}>
          <ContentIn {...this.state} {...this.props} />
        </Layout.Content>
      </Layout>
    );
  }
}

export default withRouter(App);
